import React, { useState, useEffect } from 'react';
import {
    Box,
    Card,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';

import CustomButton from "../../../../components/CustomButton/CustomButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEye, faTimes } from "@fortawesome/free-solid-svg-icons";
import { IProduct } from '../../../../interfaces/Products';

interface IProps {
    updateDate?: string;
    onDismiss: Function;
    priceUpdatedProducts: IProduct[];
}

export const UpdatedProductPricesSection = ({ updateDate, onDismiss, priceUpdatedProducts }: IProps) => {
    const [viewUpdatedProducts, setViewUpdatedProducts] = useState(false)

    return (
        <>
            {!!updateDate ? (<>

                <Card sx={{ padding: 2, marginBottom: 2, backgroundColor: '#63cbd4', borderRadius: 5 }}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center">

                        <CustomButton
                            sx={{ color: 'white', gap: "8px" }}
                            variant="contained"
                            onClick={() => setViewUpdatedProducts(true)}
                        >
                            <FontAwesomeIcon
                                icon={faEye as IconProp}
                                className="icon"
                            />
                            <Typography>
                                View All Updated Products
                            </Typography>
                        </CustomButton>

                        <Typography color="white" variant="h4">
                            A number of products have been updated on {updateDate}
                        </Typography>

                        <CustomButton
                            sx={{ color: 'white' }}
                            variant="contained"
                            onClick={() => onDismiss()}
                        >
                            Dismiss
                        </CustomButton>
                    </Box>
                </Card>
                <Dialog maxWidth="md" open={viewUpdatedProducts} onClose={() => setViewUpdatedProducts(false)}>
                    <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h3" sx={{ flex: 1 }}>List of products' prices recently updated</Typography>
                        <IconButton onClick={() => setViewUpdatedProducts(false)}>
                            <FontAwesomeIcon
                                icon={faTimes as IconProp}
                                className="icon"
                            />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Model</TableCell>
                                    <TableCell>Description Name</TableCell>
                                    <TableCell>Price Updated on</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    priceUpdatedProducts?.map(product =>
                                        <TableRow key={product._id}>
                                            <TableCell>{product.model_number}</TableCell>
                                            <TableCell>{product.description}</TableCell>
                                            <TableCell>{new Date(product.price_updated!)?.toLocaleString()}</TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </DialogContent>
                </Dialog>
            </>
            ) : (<></>)}
        </>
    )

}