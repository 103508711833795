import { useMemo } from 'react';
import { User } from "../interfaces/Users";

/**
 * @todo replace with a better pattern, this will trigger infinte loops in useEffect due to refreshing the reference every time
 */
const useUserData = (): User => {
    const userInfo: User = JSON.parse(sessionStorage.getItem("USER_INFO")!);

    return useMemo(() => userInfo, [userInfo]);
}

export default useUserData;