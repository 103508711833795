import React from 'react';
import {
    CircularProgress,
    Box,
    Typography,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
  } from '@mui/material';
  import { useReactToPrint } from 'react-to-print';
import { IProduct } from '../../interfaces/Products';
import CustomButton from "../../components/CustomButton/CustomButton";
import Modal from '../../components/Modal/Modal';
import { usePermissionChecker } from '../../hooks/useRoles';

export interface IProductPrintModalProps {
    state: boolean,
    onClose: (data: any) => void,
    loading: boolean,
    products: IProduct[]

}

export const ProductPrintModal = ({state, onClose, loading, products}: IProductPrintModalProps) => {

    const printComponentRef = React.useRef<any>();
    const permissionChecker = usePermissionChecker();

    const handlePrint = useReactToPrint({
      //@ts-ignore
      content: () => printComponentRef.current,
    });

    return (       

        <Modal
        size="large"
        state={state}
        onClose={() => onClose(false)}
      >
        <CustomButton
          variant="contained"
          onClick={handlePrint}
          sx={{padding: "5px 15px", color: "white"}}>
            Print 🖨
        </CustomButton>
        <hr/>

        <Box ref={printComponentRef}>
          <Box sx={{padding: '40px 20px'}}>
            <Typography variant="h1">
              Products List
            </Typography>
            <Typography sx={{marginTop: '5px'}} variant="h4">
            { (new Date()).toLocaleDateString() }  { (new Date()).toLocaleTimeString() }
            </Typography>
            <Typography sx={{marginTop: '5px'}} variant="h5">
              {products.length} Products Found
            </Typography>
          </Box>
          
          { loading ? <CircularProgress style={{ color: "#00AEB9" }} /> :
            <Box sx={{ padding: '0px 20px'}}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Model No</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Subcategory</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Product Variant</TableCell>
                      <TableCell>Product Size</TableCell>
                      { permissionChecker('see-retail-price')
                        && <TableCell><b>€ Your</b></TableCell>}
                      { permissionChecker('see-cash-price')
                        && <TableCell><b>€ Cash</b></TableCell>}
                      { permissionChecker('see-best-cash-price')
                        && <TableCell><b>€ Best Cash</b></TableCell>}
                      { permissionChecker('see-your-price')
                        && <TableCell><b>€ Retail</b></TableCell>}
                      { permissionChecker('see-wholesale-price')
                        && <TableCell><b>€ Special Whole sale</b></TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((product: IProduct, index) => (
                      <TableRow
                        key={`${index + product.description}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell>
                          {product.model_number}
                        </TableCell>
                        <TableCell>
                          {product.category ? product.category?.name : <i>no category</i>}
                        </TableCell>
                        <TableCell>
                          {product.subcategory ? product.subcategory?.name : <i>no subcategory</i>}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {product.description}
                        </TableCell>
                        <TableCell>
                          {product.product_variant}
                        </TableCell>
                        <TableCell>
                          {product.product_size}
                        </TableCell>
                        {permissionChecker('see-retail-price') &&
                            <TableCell>
                                {product.your_price ? product.your_price : '-'} €
                            </TableCell>}
                        {permissionChecker('see-cash-price') &&
                            <TableCell>
                                {product.cash_price ? product.cash_price : '-'} €
                            </TableCell>}
                        {permissionChecker('see-best-cash-price') &&
                            <TableCell>
                                {product.best_cash_price ? product.best_cash_price : '-'} €
                            </TableCell>}
                        {permissionChecker('see-your-price') &&
                            <TableCell>
                                {product.retail_price ? product.retail_price : '-'} €
                            </TableCell>}
                        {permissionChecker('see-wholesale-price') &&
                            <TableCell>
                                {product.special_wholesale_price ? product.special_wholesale_price : '-'} €
                            </TableCell>}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                  {products.length === 0 ? <Typography variant="h2" sx={{textAlign: 'center', padding:5}}> No Products Found </Typography> : ''}
              </TableContainer>
            </Box>
          }
        </Box>
      </Modal>
    );
}