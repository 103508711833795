import { Box, Grid } from "@mui/material";
import React from "react";
import RosterCard from "../../components/RosterCard/RosterCard";
import "./Rosters.scss";

const Rosters = ({ exportRoster, data }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {data?.map((item: any, index: any) => (
          <Grid item md={3} sm={6} xs={12} key={index}>
            <span onClick={() => exportRoster(item)}>
              <RosterCard key={index} item={item} />
            </span>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Rosters;
