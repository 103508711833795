import React from "react";
import "./GoBackButton.scss";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {Box} from "@mui/material";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const GoBackButton = () => {
  const history = useHistory();
  const goBack = () => {
    // @ts-ignore
    history.goBack();
  };
  return (
    <button className="goBackBtn textPrimary px-3 py-1" onClick={goBack}>
      <FontAwesomeIcon icon={faChevronLeft as IconProp} color="#fff" className="icon"/>
      <Box sx={{display: {md: "initial", xs: "none"}}}>Go Back</Box>
    </button>
  );
};

export default GoBackButton;
