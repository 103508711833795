import initialState from "../initialState";
import { createAction, createReducer } from "@reduxjs/toolkit";
// import { START_SESSION, END_SESSION } from "../actions/session";

const startSession = createAction('START_TRADE_API_SESSION');
const endSession = createAction('END_TRADE_API_SESSION');

const session = createReducer(initialState.session, (builder) => {
  builder
    .addCase(startSession, (state, action) => {
      state.token = action.payload ?  action.payload! : '';
    })
    .addCase(endSession, (state, action) => {
      return initialState.session;
    })
});

export default session;
