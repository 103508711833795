import React from "react";
import LoginCoverBackground from "./LoginCoverBackground";

export interface ResetPasswordInterface {
  reset: any;
  setPassword: any;
  email: any;
}

const ResetPasswordForm: React.FC<ResetPasswordInterface> = ({
  setPassword,
  email,
  reset,
}: ResetPasswordInterface) => {
  return (
    <div className="container-fluid p-0" style={{ height: "100vh" }}>
      <div className="row h-100">
        <LoginCoverBackground />

        <div
          className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 login-container"
          style={{ padding: "100px" }}
        >
          <h3>Enter new password</h3>
          <label htmlFor="email" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            aria-describedby="emailHelp"
            value={email}
            disabled
          />
          <br></br>
          <label htmlFor="password" className="form-label">
            New Password
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            aria-describedby="emailHelp"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            onClick={reset}
            className="btn btn-primary w-100 mt-2"
          >
            reset password
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
