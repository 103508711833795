import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faFile, faFilePdf, faFileImage, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import NoResourceAvailable from "../NoResourceAvailable/NoResourceAvailable";
import "./GenericTable.scss";

export interface GenericTableParams {
  list: any[];
  totalCount: number;
  selectedRows: any;
  page: number;
  onSelectedChange: any;
  rowsPerPage: number;
  onRowsChange: any;
  onPageChange: any;
  numberOfData: number;
  model: string;
  passRow: any;
  image: string;
  tableConfig: any;
  loading: boolean;
  showCheckbox?: boolean;
  frontendPagination?: boolean;
}

const GenericTable = (params: GenericTableParams) => {
  const [selected, setSelected] = useState<any>([]);
  const [active, setActive] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = (row: any) => {
    params.passRow(row);
  };

  const renderFiles = (row: any, object: any): any => {
    let data: any = row[object.attribute];
    let max_files: number = 2;

    if(Array.isArray(data)) {
      return (
        <div>
          {(data.length == 0) ? 'none' : (
            data.map((file, index) => (
            (index >= max_files) ? '' : <a
              rel="noreferrer"
              target="_blank"
              key={file.url}
              href={file.url}>
                <FontAwesomeIcon
                  icon={
                    file.type == 'image' ? (faFileImage as IconProp) : (faFile as IconProp)
                  }
                  className="fontAwesomeIcon"
                />
            </a>))
          )}
          <>{(data.length > max_files) ? '...' : ''}</>
        </div>
      )
    } else {
      return 'not a files array...';
    }
  }

  const handleSelectionChange = (rowId: any) => {
    let data = [...params.selectedRows];
    if (data.includes(rowId)) {
      data = data.filter((item) => item !== rowId);
    } else {
      data.push(rowId);
    }
    setSelected(data);
    params.onSelectedChange(data);
  };
  const selectAll = (checked: any) => {
    let dataList = [...params.selectedRows];
    if (checked) {
      params.list.forEach((item: any) => {
        if (!dataList.includes(item._id)) {
          dataList.push(item._id);
        }
      });
      dataList = [...new Set(dataList)];
      setSelected(dataList);
    } else {
      params.list.forEach((item: any) => {
        dataList = dataList.filter((listItem) => listItem !== item._id);
      });
      setSelected(dataList);
    }
    params.onSelectedChange(dataList);
  };
  return (
    <Box
      alignItems="flex-start"
      className="usersList w-100"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {loading || params.loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            color: "#00AEB9",
          }}
        >
          <CircularProgress style={{ color: "#00AEB9" }} />
        </div>
      ) : (
        <>
          <TableContainer component={Paper} className="tableContainer">
            <Table className="usersListTable">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    {params.showCheckbox !== false && (
                      <Checkbox
                        className="checkBox"
                        color="primary"
                        onChange={(e) => selectAll(e.target.checked)}
                        checked={
                          params.selectedRows?.length > 0 &&
                          params.selectedRows?.length === params.list?.length
                        }
                      />
                    )}
                  </TableCell>
                  {params.tableConfig.map((row: any, index: number) => (
                    <TableCell key={index} align="center">
                      {row.heading}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {params.totalCount > 0 && (
                <TableBody>
                  {params.frontendPagination === true
                    ? params.list
                        .slice(
                          params.page * params.rowsPerPage,
                          params.page * params.rowsPerPage + params.rowsPerPage
                        )
                        .map((row: any, index: number) => (
                          <TableRow
                            key={index}
                            onClick={() => {
                              navigate(row);
                              setActive(row);
                            }}
                            className={active === row ? "activeRow" : ""}
                          >
                            <TableCell align="left">
                              {params.showCheckbox !== false && (
                                <Checkbox
                                  className="checkBox"
                                  color="primary"
                                  value={row._id ? row._id : ""}
                                  checked={params.selectedRows.includes(
                                    row._id
                                  )}
                                  onChange={(e) =>
                                    handleSelectionChange(row._id)
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                              )}
                            </TableCell>
                            {params.tableConfig.map(
                              (object: any, index: number) => (
                                <TableCell
                                  key={index}
                                  className="pointer"
                                  align="center"
                                >
                                  {/* if column is date, display formatted date */}
                                  {object.type === "date" &&
                                    (dayjs(row[object.attribute]).format(
                                      "MM/DD/YYYY"
                                    )
                                      ? dayjs(row[object.attribute]).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "none")}
                                  {/*  */}
                                  {object.type === "boolean" &&
                                    (row[object.attribute] == true ? (
                                      <FontAwesomeIcon icon={faCheck as IconProp} />
                                    ) : (
                                      <FontAwesomeIcon icon={faTimes as IconProp} />
                                    ))}
                                  {(object.type === "string" ||
                                    object.type === "number") &&
                                    (row[object.attribute]
                                      ? row[object.attribute]
                                      : "none")}
                                  {object.type == "currency" &&
                                    (row[object.attribute]
                                      ? "€ " + row[object.attribute]
                                      : "none")}

                                  {object.type == "link" &&
                                    (row[object.attribute] ? (
                                      <a target="_blank" rel="noreferrer"
                                        href={row[object.attribute]}>
                                        <FontAwesomeIcon
                                          icon={faFilePdf as IconProp}
                                          className="fontAwesomeIcon"
                                        />
                                      </a>
                                    ) : "none")}

                                  {/* if the object is an array of files */}
                                  {object.type == "files_array" && renderFiles(row, object)}
                                </TableCell>
                              )
                            )}
                          </TableRow>
                        ))
                    : params.list.map((row: any, index: number) => (
                        <TableRow
                          key={index}
                          onClick={() => {
                            navigate(row);
                            setActive(row);
                          }}
                          className={active === row ? "activeRow" : ""}
                        >
                          <TableCell align="left">
                            {params.showCheckbox !== false && (
                              <Checkbox
                                className="checkBox"
                                color="primary"
                                value={row._id ? row._id : ""}
                                checked={params.selectedRows.includes(row._id)}
                                onChange={(e) => handleSelectionChange(row._id)}
                                onClick={(e) => e.stopPropagation()}
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            )}

                            {/* {console.log("rowData=====> ", row)} */}
                          </TableCell>
                          {params.tableConfig.map(
                            (object: any, index: number) => (
                              <TableCell
                                key={index}
                                className="pointer"
                                align="center"
                              >
                                {/* if column is date, display formatted date */}
                                {object.type === "date" &&
                                  (dayjs(row[object.attribute]).format(
                                    "MM/DD/YYYY"
                                  )
                                    ? dayjs(row[object.attribute]).format(
                                        "MM/DD/YYYY"
                                      )
                                    : "none")}
                                {/*  */}
                                {object.type === "boolean" &&
                                  (row[object.attribute] == true ? (
                                    <FontAwesomeIcon icon={faCheck as IconProp} />
                                  ) : (
                                    <FontAwesomeIcon icon={faTimes as IconProp} />
                                  ))}
                                {(object.type === "string" ||
                                  object.type === "number") &&
                                  (row[object.attribute]
                                    ? row[object.attribute]
                                    : "none")}
                                {object.type == "currency" &&
                                  (row[object.attribute]
                                    ? "€ " + row[object.attribute]
                                    : "none")}
                                {object.type == "link" &&
                                  (row[object.attribute] ? (
                                    <a
                                      href={row[object.attribute]}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <FontAwesomeIcon
                                        icon={faFilePdf as IconProp}
                                        className="fontAwesomeIcon"
                                      />
                                    </a>
                                  ) : (
                                    "none"
                                  ))}
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      ))}
                </TableBody>
              )}
            </Table>
            {params.totalCount === 0 && (
              <NoResourceAvailable
                resource={params.model + "s"}
                image={params.image}
                addButtonLine={true}
              />
            )}
          </TableContainer>
          {/* <h1>Number Of Data{params.numberOfData}</h1> */}
          {params.totalCount > 0 && (
            <>
              <TablePagination
                className="pagination"
                component="div"
                page={params.page}
                rowsPerPage={params.rowsPerPage}
                count={params.numberOfData}
                onPageChange={params.onPageChange}
                onRowsPerPageChange={params.onRowsChange}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};
export default GenericTable;
