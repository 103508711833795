import React from "react";
import { CircularProgress, Box, Typography, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";
import AddEditUserForm from "../../../components/PageComponents/Users/AddEditUserForm";
import useScreenSize from "../../../hooks/useScreenSize";
import useStoreSessionSelector from "../../../hooks/useStoreSessionSelector";
import UserService from "../../../services/Entities/UserService";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../store/actions/alerts";
import "./AddEditUser.scss";
import { usePermissionChecker } from "../../../hooks/useRoles";
// import showErrorAlert from '../../../components/Alerts/ErrorAlert/ErrorAlert';

const AddEditUser = () => {
  const [formState, setFormState] = React.useState("create");
  const [object, setObject] = React.useState({
    name: "",
    role: "",
    email: "",
    password: "",
  });
  const [userRole, setUserRole] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const usersClient = new UserService();
  const session = useStoreSessionSelector();
  const { id }: any = useParams();
  const history = useHistory();
  const device = useScreenSize();
  const dispatch = useDispatch();

  const permissionChecker = usePermissionChecker();

  const deleteResourceHandler = () => {
    if (!permissionChecker("delete-user", true)) return;

    if (!id) {
      dispatch(
        showErrorAlert({
          message: "There is no user to delete! You are in create-mode.",
        })
      );
    } else {
      const payload = {
        id: id,
        token: session.token,
      };
      usersClient
        .deleteResource(payload)
        .then((res) => {
          history.push("/users");
        })
        .finally(() => setLoading(false));
    }
  };

  const fetchSpecificResource = () => {
    setLoading(true);

    usersClient
      .getSpecificResource(id, { token: session.token })
      .then((res) => {
        setObject(res["data"]["data"][0]);
        setUserRole(res["data"]["data"][0].role);
        setFormState("edit");
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (id) fetchSpecificResource();
  }, [id]);

  const submitResource = () => {
    if (formState === "create") {
      if (!permissionChecker("create-user", true)) return;

      setLoading(true);

      const payload = {
        ...object,
        role: userRole,
        token: session.token,
      };
      usersClient
        .postResource(payload)
        .then((res) => {
          dispatch(showSuccessAlert("Submitted Successfully!"));
          history.push("/users/" + res["data"]["data"]["_id"]);
        })
        .finally(() => setLoading(false));
    } else {
      if (!permissionChecker("edit-user", true)) return;

      setLoading(true);

      const payload = {
        id: id,
        ...object,
        role: userRole,
        token: session.token,
      };
      usersClient
        .patchResource(payload)
        .then((res) => {
          dispatch(showSuccessAlert("Submitted Successfully!"));
          setObject(res["data"]["data"][0]);
          fetchSpecificResource();
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "scroll",
      }}
    >
      <GenericHeader
        deleteResourceHandler={deleteResourceHandler}
        selectedRows={[{id}]}
        model="user"
      />

      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            padding: 5,
            margin: 5,
            width: device === "mobile" ? "100%" : "75%",
          }}
          className="card shadow-lg"
        >
          <Typography variant="h3" sx={{ color: "#00AEB9" }}>
            {formState === "create" ? "Add" : "Edit"} User
          </Typography>

          <AddEditUserForm
            object={object}
            setObject={setObject}
            userRole={userRole}
            setUserRole={setUserRole}
          />

          <Button
            variant="contained"
            onClick={submitResource}
            sx={{ color: "white" }}
            disabled={
              !object?.name ||
              !object?.email ||
              !object?.password ||
              !object?.role
            }
          >
            {loading ? <CircularProgress style={{ color: "#fff" }} /> : "SAVE"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditUser;
