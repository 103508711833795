// eslint-disable
import { faPaperclip, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CircularProgress,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, FC, useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import AddEditViewProductForm from "../../components/PageComponents/Products/AddEditViewProductForm";
import { usePermissionChecker } from "../../hooks/useRoles";
import useStoreSessionSelector from "../../hooks/useStoreSessionSelector";
import { ICategory } from "../../interfaces/Category";
import { IProduct, IProductFactory } from "../../interfaces/Products";
import {
  CategoriesApi,
  ProductsApi,
  FilesApi,
} from "../../services/Entities/ProductPricingService";
import { showErrorAlert, showSuccessAlert } from "../../store/actions/alerts";
import "./AddEditViewProduct.scss";
import pdf_file from '../../assets/pdf_file.png';

const AddEditViewProduct: FC = ({
  id = undefined,
  setModal,
  refreshData,
  modal,
}: any) => {
  const [product, setProduct] = useState<IProduct>(IProductFactory);
  const [categoryOptions, setCategoryOptions] = useState<ICategory[]>([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState<
    ICategory[]
  >([]);
  const [categoriesLoading, setCategoriesLoading] =
    useState<boolean>(false);
  const [subcategoriesLoading, setSubcategoriesLoading] =
    useState<boolean>(false);
    
  const [userCanModifyForm, setUserCanModifyForm] = useState<boolean>(false)


  const [flyer, setFlyer] = useState<any>();
  const [file, setFile] = useState<any[]>([]);
  const [files_id, setFileId] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const categoriesApi = new CategoriesApi();
  const productsApi = new ProductsApi();
  const filesApi = new FilesApi();

  const session = useStoreSessionSelector();
  const dispatch = useDispatch();

  const permissionChecker = usePermissionChecker();
  
  useEffect(() => {
    const userCanCreateProduct = (permissionChecker("create-product") && !product._id);
    const userCanEditProduct = (permissionChecker("edit-product") && !!product._id);
    setUserCanModifyForm(userCanCreateProduct || userCanEditProduct);
  }, [permissionChecker, product])
  
  useEffect(() => {
    fetchSpecificResource();
    fetchCategories();
  }, []);

  useEffect(() => fetchSpecificCategory(), [product.category]);

  const fileUpload = async () => {
    if (!permissionChecker("create-product", true)) return;
    for (var i = 0; i < file.length; i++) {
      let f = new FormData();
      f.append("file", file[i]);

      setLoading(true);
      await filesApi
        .postResource(f)
        .then((res) => {
          dispatch(showSuccessAlert("File Added Successfully!"));
          files_id.push(res["data"]["data"]["_id"]);
        })
        .finally(() => setLoading(false));
    }
    product["files"]?.push(...files_id);
  };

  const postProduct = async () => {
    if (!permissionChecker("create-product", true)) return;
    let payload: FormData = buildForm();

    product["files"] = [];
    await fileUpload();

    await productsApi
      .postResource(product)
      .then(() => {
        dispatch(showSuccessAlert("Product Added Successfully!"));
        refreshData(true);
        setModal(false);
        clearForm(payload);
      })
      .finally(() => setLoading(false));
  };

  const patchProduct = async () => {
    if (!permissionChecker("edit-product", true)) return;
    let payload: FormData = buildForm();

    await fileUpload();

    product["id"] = id;

    await productsApi
      .patchResource(product)
      .then(() => {
        dispatch(showSuccessAlert("Product Edited successfully!"));
        refreshData(true);
        setModal(false);
        clearForm(payload);
      })
      .finally(() => setLoading(false));
  };

  const clearForm = (payload: FormData): void => {
    payload.forEach((value, key) => {
      payload.delete(key);
    });
  };

  const buildForm = (): FormData => {
    let formDataPayload = new FormData();
    for (var key in product) {
      if (key !== "category" && key !== "subcategory") {
        // @ts-ignore
        formDataPayload.append(key, product[key]);
      }
      if (key === "category" || key === "subcategory") {
        if (product[key]._id) {
          formDataPayload.delete(key);
          formDataPayload.append(key, product[key]._id);
        } else formDataPayload.append(key, product[key]);
      }
    }

    if (flyer) formDataPayload.append("flyer", flyer);

    for (const f of file) {
      formDataPayload.append("files", f);
    }

    let cleanFormDataPayload = cleanFormData(formDataPayload);
    return cleanFormDataPayload;
  };

  const cleanFormData = (formData: FormData): FormData => {
    formData.forEach((value, key) => {
      if (value === "null" || value === "undefined") formData.delete(key);

      if (value === "true" || value === "false")
        formData.set(key, value === "true" ? "1" : "0");
    });

    formData.delete("deleted_at");
    return formData;
  };

  const fetchSpecificResource = () => {
    if (id !== undefined) {
      setLoading(true);
      productsApi
        .getSpecificResource(id, { token: session.token })
        .then((res) => {
          setProduct(res.data?.data[0]);
        })
        .finally(() => setLoading(false));
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    //TODO: handle those types boolean and date
    e.preventDefault();
    let tempProduct: IProduct = { ...product };
    const key: any = e?.target?.name;
    const value: any = e?.target?.value;

    if (Object.keys(product).includes(key))
      tempProduct = { ...product, [key]: value };

    setProductWithPermissionCheck(tempProduct);
  };

  const setProductWithPermissionCheck = (data) => {
    if(!userCanModifyForm) return;
    setProduct(data);
  }

  const fetchSpecificCategory = () => {
    setSubcategoriesLoading(true);
    if (product.category) {
      let id = product.category._id ? product.category._id : product.category;
      categoriesApi
        .getSpecificResource(id, {})
        .then((response) => {
          setSubcategoryOptions(response.data.data[0].subcategories);
        })
        .finally(() => setSubcategoriesLoading(false));
    }
  };

  const fetchCategories = () => {
    const payload = { token: session.token };
    setCategoriesLoading(true);

    categoriesApi
      .getAllResources(payload)
      .then((response) => {
        setCategoryOptions(response.data.data);
      })
      .finally(() => setCategoriesLoading(false));
  };

  const handleFileUpload = (e: any) => {
    setFile(e.target.files);
  };

  const deleteResourceHandler = (e: any, product: any, file: File) => {

    e.preventDefault(); // So the image doesn't open
    if (!permissionChecker("delete-user", true)) return;

    const result = window.confirm("Are you sure you want to delete?");
    if(!result) return;

    if (!id) {
      dispatch(
        showErrorAlert({
          message: "There is no user to delete! You are in create-mode.",
        })
      );
    } else {
      const payload = {
        id: id,
        token: session.token,
      };
      deleteFile(product, file);
    }
  };

  const deleteFile = (product: any, file: File) => {
    const payload = {
      id: file["_id"],
      token: session.token,
    };

    filesApi
      .deleteResource(payload)
      .then((res) => {
        dispatch(showSuccessAlert("File Deleted Successfully!"));
        refreshData(true);
        setModal(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="py-2d">
        <h1 style={{ marginBottom: "20px" }}>
          {id !== undefined ? "Edit Product" : "Add New Product"}
        </h1>
      </div>
      <AddEditViewProductForm
        product={product}
        handleChange={handleChange}
        categoriesLoading={categoriesLoading}
        categoryOptions={categoryOptions}
        subcategoryOptions={subcategoryOptions}
        subcategoriesLoading={subcategoriesLoading}
        setProduct={setProductWithPermissionCheck}
        permissionChecker={permissionChecker}
        disabled={!userCanModifyForm}
      />

      <Typography variant="h3" style={{paddingTop: 25}}>
        {product.files?.length ?? 0} Files Attached
      </Typography>
      
      <div className="imagesContainer">
        {product.files?.length && product.files.map((file: any) => (
          <div className="imageHolder">
            <a
              href={file.url}
              target="_blank"
              className="flyerUrl"
            >
              <img
                src={file.type == "image" ? file.url : pdf_file}
                className={file.type == "image" ? "flyerImage" : "iconImage"}/>
              <button
                disabled={!userCanModifyForm}
                className="fileDelete"
                onClick={(e) => deleteResourceHandler(e, product, file)}
              >
                <FontAwesomeIcon icon={faTrash} style={{ float: "left" }} />
              </button>
            </a>
          </div>
        ))}
      </div>

      <div className="mt-3" style={{ transition: "0.3s all" }}>
        <p
          className="mb-0"
          style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.54)" }}
        >
          Attach a file
        </p>
        <input
          disabled={!userCanModifyForm}
          name="files"
          type="file"
          multiple
          onChange={(e) => handleFileUpload(e)}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ display: "flex" }}>
          <button
            className="cancelBtn"
            style={{ margin: "15px 0px" }}
            onClick={() => setModal(false)}
          >
            Cancel
          </button>

          <button
            className="submitBtn"
            style={{ margin: "15px" }}
            disabled={
              !product.category ||
              !product.subcategory ||
              !product.model_number ||
              loading || !userCanModifyForm
            }
            onClick={() => (id === undefined ? postProduct() : patchProduct())}
          >
            {loading ? (
              <CircularProgress style={{ width: "30px" }} />
            ) : id === undefined ? (
              "Add Product"
            ) : (
              "Edit Product"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddEditViewProduct;
