import { ICategory } from "./Category";

export interface IProduct {
  _id?: string | undefined;
  model_number: string;
  cash_price: number | undefined;
  retail_price: number | undefined;
  special_wholesale_price: number | undefined;
  best_cash_price: number | undefined;
  your_price: number | undefined;
  guarantee?: string;
  is_available: boolean;
  category: ICategory | any;
  flyer_url?: string;
  files?: any[];
  subcategory: ICategory | any;
  product_size: string;
  product_variant: string;
  description: string;
  available_eta: number | undefined;
  created_at?: Date;
  updated_at?: Date;
  price_updated?: Date;
}

/**
 * Create an object of type IProduct
 */
export const IProductFactory = (
  attributes: Partial<IProduct> = {}
): IProduct => {
  return {
    model_number: "",
    cash_price: 0,
    retail_price: 0,
    special_wholesale_price: 0,
    best_cash_price: 0,
    your_price: 0,
    guarantee: "",
    is_available: true,
    available_eta: Date.now(),
    category: null,
    subcategory: null,
    product_size: "",
    product_variant: "",
    description: "",
    ...attributes,
  };
};
