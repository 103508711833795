import initialState from "../initialState";
import { SHOW_QR_MODAL, HIDE_QR_MODAL } from "../actions/qrmodal";
import { createReducer } from "@reduxjs/toolkit";

const qrmodal = createReducer(initialState.qr, {
    [SHOW_QR_MODAL]: (state, action) => {
        state.imgUrl = action.payload;
        state.email = action.email;
        state.password = action.password;
    },
    [HIDE_QR_MODAL]: () => {
        return initialState.qr;
    },
})

export default qrmodal;