import { useMemo } from "react"
import AuthenticationService from "../../services/Entities/AuthenticationService"
import CategoryService from "../../services/Entities/categoryService"
import { ProductsApi } from "../../services/Entities/ProductPricingService"
import SubCategoryService from "../../services/Entities/subCategoryService"
import UserService from "../../services/Entities/UserService"

export const useUsersService = () => {
    return useMemo(() => new UserService(), [])
}

export const useAuthService = () => {
    return useMemo(() => new AuthenticationService(), [])
}

export const useProductService = () => {
    return useMemo(() => new ProductsApi(), [])
}

export const useCategoryService = () => {
    return useMemo(() => new CategoryService(), [])
}

export const useSubCategoryService = () => {
    return useMemo(() => new SubCategoryService(), [])
}