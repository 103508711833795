import { CircularProgress, Box } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import noDataPic from "../../assets/no_data_x.gif";
import GenericHeader from "../../components/GenericHeader/GenericHeader";
import NoResourceAvailable from "../../components/NoResourceAvailable";
import Rosters from "../../components/Rosters/Rosters";
import useStoreSessionSelector from "../../hooks/useStoreSessionSelector";
import { ICategory } from "../../interfaces/Category";
import { IProduct } from "../../interfaces/Products";
import {
  CategoriesApi,
  ProductsApi,
} from "../../services/Entities/ProductPricingService";
import { ProductPrintModal } from "../ProductPrintModal/ProductPrintModal";
import "./ProductPrices.scss";

const ProductPrices = () => {
  const history = useHistory();
  const session = useStoreSessionSelector();
  const [categories, setCategories] = React.useState<ICategory[]>([]);
  const [products, setProducts] = React.useState<IProduct[]>([]);
  const [loading, setLoading] = React.useState(false);

  const [loadingPrintModal, setLoadingPrintModal] = React.useState(false);
  const [printModalOpen, setPrintModalOpen] = React.useState(false);

  const catgoriesService = new CategoriesApi();
  const productsService = new ProductsApi();

  const navigateToCategory = (category: ICategory) => {
    history.push("/productPrices/categories/" + category._id);
  };

  const exportDataAsPDF = () => {
    console.log("Exporting data as pdf...");
    setPrintModalOpen(true);
    fetchAllProducts();
  };

  const fetchAllProducts = () => {
    setLoadingPrintModal(true);

    productsService
      .getAllResources({ token: session.token })
      .then((response) => setProducts(response.data.data))
      .finally(() => setLoadingPrintModal(false));
  };

  const fetchData = () => {
    setLoading(true);

    catgoriesService
      .getAllResources({ token: session.token })
      .then((response) => setCategories(response.data.data))
      .finally(() => setLoading(false));
  };

  const exportDataAsCSV = () => {
    setLoading(true);
    productsService
      .exportCSV()
      .then(() => console.log("done downloading"))
      .catch((err) => console.log({ err }))
      .finally(() => setLoading(false));
  };

  console.log("rendered");
  React.useEffect(fetchData, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "scroll",
      }}
    >
      <GenericHeader
        customButtonsLabels={[
          "Export Data as PDF 📃",
          // "Export Data as Excel"
        ]}
        customButtonsHandlers={[
          () => exportDataAsPDF(),
          // () => exportDataAsCSV(),
        ]}
      />

      {loading ? (
        <CircularProgress />
      ) : categories.length === 0 ? (
        <NoResourceAvailable
          message="There are currently no categories"
          image={noDataPic}
        />
      ) : (
        <Rosters
          data={categories}
          exportRoster={navigateToCategory}
          className="align-self-center"
        />
      )}

      <ProductPrintModal
        state={printModalOpen}
        onClose={setPrintModalOpen}
        loading={loadingPrintModal}
        products={products}
      />
    </Box>
  );
};

export default ProductPrices;
