import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBars, faDollarSign, faHome, faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, List, ListItem, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SidebarItem } from "../../interfaces/SidebarItem";
import { logout } from "../../store/actions/session";
import { ConfirmDialog } from "../Dialogs";
import "./MobileMenu.scss";

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuBar: {
            backgroundColor: "#1b1b2c"
        },
        menuButton: {
            marginRight: theme.spacing(2),
            color: "#fff",
            padding: 0,
            margin: 0
        },
        menuList: {
            width: "100%",
            height: "100%",
            listStyle: "none",
            backgroundColor: "#1b1b2c",
            transition: "0.5s all"
        },
        menuListItem: {
            color: "#fff",
            margin: "10px 0px",
            padding: "10px",
            width: "100%",
            backgroundColor: "#212135"
        },
        title: {
            flexGrow: 1,
        },
    }),
);

const MobileMenu = () => {
    const dispatch = useDispatch();
    const [menu, setMenu] = useState(false);
    //@ts-ignore
    const classes: any = useStyles();
    const history = useHistory();

    const initConfirmConfig = {
        title: "",
        body: "",
        visible: false,
    };

    const [confirmConfig, setConfirmConfig] = useState(initConfirmConfig);

    const routes: SidebarItem[] = [
        { path: "/", label: "Main Menu", routeActiveHandler: "", icon: faHome as IconProp },
        { path: "/users", label: "Users", routeActiveHandler: "users", icon: faUser as IconProp },
        { path: "/productPrices/categories", label: "Product Prices", routeActiveHandler: "productPrices/categories", icon: faDollarSign as IconProp},
    ]

    const confirmLogout = () => {
        setConfirmConfig({
            title: "Please Confirm",
            body: "Are you sure you want to log out?",
            visible: true,
        });
    };

    const navigateTo = (route: string) => {
        history.push(route);
        setMenu(false);
    }

    const logoutUser = () => {
        dispatch(logout());
    };
    return (
        <>
            <ConfirmDialog
                confirmConfig={confirmConfig}
                executeActionCallback={logoutUser}
                setConfirmConfig={setConfirmConfig}
            />
            <div className={classes.menuBar + " p-3"}>
                <IconButton onClick={() => setMenu(!menu)} className={classes.menuButton} aria-label="menu">
                    <FontAwesomeIcon icon={faBars as IconProp} />
                </IconButton>
                {menu && (
                <>
                    <Box
                        className={classes.menuList}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <List className={classes.menuList}>
                            {routes.map((route, index) => (
                                <ListItem key={index} className={classes.menuListItem} onClick={() => navigateTo(route.path)}>
                                    {/* @ts-ignore */}
                                    <FontAwesomeIcon icon={route?.icon as IconProp} style={{marginRight: "20px"}}/>
                                    <Typography variant="body1">{route.label}</Typography>
                                </ListItem>
                            ))}
                            <ListItem className={classes.menuListItem} onClick={() => confirmLogout()}>
                                <FontAwesomeIcon icon={faSignOutAlt as IconProp} style={{marginRight: "20px"}}/>
                                <Typography variant="body1">Log Out</Typography>
                            </ListItem>
                        </List>
                    </Box>
                </>
            )}
            </div>
            
        </>
    )
}

export default MobileMenu;
