import { useState, useEffect } from "react";

import {
  Button,
  CircularProgress,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  ICategory,
  ICategoryFactory,
  ISubcategory,
} from "../../../../interfaces/Category";
import { TFormStates } from "../../../../interfaces/Forms";
import { usePermissionChecker } from "../../../../hooks/useRoles";

export interface ICategoryFormProps {
  object: ICategory;
  setObject: (data: ICategory) => void;
  loading: boolean;
  submit: () => void;
  formState: TFormStates;
  createSubcategory: (data: Partial<ISubcategory>) => void;
  deleteSubcategory: (data: ISubcategory) => void;
  editSubcategory: (data: ISubcategory) => void;
}

export const CategoryForm = ({
  object,
  setObject,
  loading,
  submit,
  formState,
  createSubcategory,
  deleteSubcategory,
  editSubcategory,
}: ICategoryFormProps) => {
  const [subcategories, setSubcategories] = useState<ISubcategory[]>([]);
  const [subcategoriesNames, setSubcategoriesNames] = useState<string[]>([]);
  const [newSubcategory, setNewSubcategory] = useState<string>("");
  const [userCanModifyForm, setUserCanModifyForm] = useState<boolean>(false);
  
  const permissionChecker = usePermissionChecker();
  
  useEffect(() => {
    const userCanCreateProduct = (permissionChecker("create-product") && !object._id);
    const userCanEditProduct = (permissionChecker("edit-product") && !!object._id);
    setUserCanModifyForm(userCanCreateProduct || userCanEditProduct);
  }, [permissionChecker, object])

  useEffect(
    () =>
      setSubcategories(object?.subcategories ? [...object.subcategories] : []),
    [object]
  );
  useEffect(
    () => setSubcategoriesNames([...subcategories.map((s) => s.name)]),
    [subcategories]
  );

  const handleChange = (input: any) => {
    if(!userCanModifyForm) return;

    let tempCategory: ICategory = object;
    tempCategory[input.name] = input.value;
    setObject({ ...tempCategory });
  };

  const handleSubcategoryChange = (event: any, index: number) => {
    if(!userCanModifyForm) return;

    let tempSubcats: string[] = subcategoriesNames;
    tempSubcats[index] = event.target.value;
    setSubcategoriesNames(tempSubcats);
  }

  return (
    <form>
      <Typography variant="h3" sx={{ color: "#00AEB9", fontSize: "20px" }}>
        {formState === "create" ? "Add" : "Edit"} Category
      </Typography>

      <TextField
        fullWidth
        label="Name"
        name="name"
        value={object ? object.name : ""}
        onChange={(e) => handleChange(e.target)}
        className="my-2"
      />

      <Divider absolute />

      {object._id && (
        <>
          <Typography variant="h4" sx={{ marginTop: 5 }}>
            Subcategories
          </Typography>

          {
            userCanModifyForm ? 
            <>
              <TextField
                fullWidth
                label="New Subcategory Name"
                value={newSubcategory}
                sx={{ marginTop: 1, width: "75%" }}
                onChange={(e) => setNewSubcategory(e.target.value)}
              />

              <Button
                variant="contained"
                sx={{ margin: 1, padding: 2, color: "white", width: "23%" }}
                onClick={() => (
                  createSubcategory({ name: newSubcategory }), setNewSubcategory("")
                )}
              >
                + Add New
              </Button>
            </> : <></>
          }

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Name</TableCell>
                  {userCanModifyForm ? <TableCell>Actions</TableCell> : <></>}
                </TableRow>
              </TableHead>

              <TableBody>
                {!!subcategoriesNames.length &&
                  !loading &&
                  subcategories.map((row: ISubcategory, index: number) => (
                    <TableRow key={row.name}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          label="Subcategory Name"
                          defaultValue={subcategoriesNames[index]}
                          onChange={(e) => handleSubcategoryChange(e, index)}
                        />
                      </TableCell>
                      {
                        userCanModifyForm ? 
                          <TableCell>
                            <Button
                              variant="contained"
                              sx={{ margin: 1, color: "white" }}
                              onClick={() =>
                                editSubcategory({
                                  ...row,
                                  name: subcategoriesNames[index],
                                })
                              }
                            >
                              Edit
                            </Button>

                            <Button
                              variant="contained"
                              color="error"
                              sx={{ margin: 1, color: "white" }}
                              onClick={() => deleteSubcategory(row)}
                            >
                              Delete
                            </Button>
                          </TableCell> :<></>
                      }
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {userCanModifyForm ? <Button
        variant="contained"
        sx={{ marginTop: 10, width: "100%", color: "white" }}
        onClick={submit}
        disabled={!object?.name}
      >
        {loading ? <CircularProgress style={{ color: "#fff" }} /> : "SAVE"}
      </Button> : <></>}
    </form>
  );
};
