import { Box, createTheme, ThemeProvider } from "@mui/material";
import React, { useMemo } from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import "./App.scss";
import ErrorAlert from "./components/Alerts/ErrorAlert/ErrorAlert";
import SuccessAlert from "./components/Alerts/SuccessAlert/SuccessAlert";
import MobileMenu from "./components/MobileMenu/MobileMenu";
import ProtectedRoute from "./components/RouteComponents/ProtectedRoute";
import SessionRoute from "./components/RouteComponents/SessionRoute";
import Sidebar from "./components/Sidebar/Sidebar";
import useScreenSize from "./hooks/useScreenSize";
import useStoreSessionSelector from "./hooks/useStoreSessionSelector";
import { CategoriesFormView, CategoriesTableView } from "./pages/Categories";
import Login from "./pages/login/Login";
import Forgot from "./pages/forgot/Forgot";
import Reset from "./pages/reset/Reset";
import ProductPriceCategory from "./pages/ProductPriceCategory/ProductPriceCategory";
import ProductPrices from "./pages/ProductPrices/ProductPrices";
import AddEditUser from "./pages/Users/AddEditUser/AddEditUser";
import UsersTableView from "./pages/Users/UsersTableView/UsersTableView";
import theme from "./services/theme";
import useUserData from "./hooks/useUserData";
import { User } from "./interfaces/Users";
import { useFreshUserData } from "./hooks/apiHooks/useFreshUserData";
declare global {
  interface Window {
    REACT_APP_API_URL: string;
    REACT_APP_STATIC_URL: string;
  }
}

const App = () => {
  // const activeUser: User = useUserData();
  const { loading: loadingActiveUser, result: activeUser, error } = useFreshUserData();

  const device = useScreenSize();
  // @ts-ignore
  const mainTheme = useMemo(() => createTheme(theme()), []);

  const session = useStoreSessionSelector();
  const [collapsed, setCollapsed] = React.useState(false);

  console.log(
    "ℹ Using API URL: ",
    process.env.REACT_APP_API_URL ??
      window.REACT_APP_API_URL ??
      "No API URL found, using prod url as fallback!"
  );
  console.log(
    "ℹ Using Static File URL: ",
    process.env.REACT_APP_STATIC_URL ??
      window.REACT_APP_STATIC_URL ??
      "No Static URL found, using prod url as fallback!"
  );

  return (
    <>
      <Box>
        <ThemeProvider theme={mainTheme}>
          <ErrorAlert />
          <SuccessAlert />
          <BrowserRouter>
            {session.token && (
              <>
                {device !== "mobile" ? (
                  <div className="p-0" style={{ float: "left" }}>
                    {
                      <Box className="sideBox">
                        <Sidebar isSidebarCollapsed={setCollapsed} />
                      </Box>
                    }
                  </div>
                ) : (
                  <MobileMenu />
                )}
              </>
            )}

            <Box className="contentBox">
              <Switch>
                {/* <ProtectedRoute path="/dashboard"
                    exact
                    token={session.token}
                    component={Dashboard} /> */}

                <Redirect exact from="/" to="/productPrices/categories" />

                <SessionRoute
                  exact
                  path="/login"
                  token={session.token}
                  component={Login}
                />

                <SessionRoute
                  exact
                  path="/forgot"
                  token={session.token}
                  component={Forgot}
                />
                <SessionRoute
                  exact
                  path="/reset"
                  token={session.token}
                  component={Reset}
                />

                {activeUser?.role == "admin" && <ProtectedRoute
                  exact
                  path="/users"
                  token={session.token}
                  component={UsersTableView}
                />}

                {activeUser?.role == "admin" && <ProtectedRoute
                  exact
                  path="/users/add"
                  token={session.token}
                  component={AddEditUser}
                />}

                {activeUser?.role == "admin" && <ProtectedRoute
                  exact
                  path="/users/:id"
                  token={session.token}
                  component={AddEditUser}
                />}

                <ProtectedRoute
                  exact
                  path="/categories"
                  token={session.token}
                  component={CategoriesTableView}
                />

                <ProtectedRoute
                  exact
                  path="/categories/add"
                  token={session.token}
                  component={CategoriesFormView}
                />

                <ProtectedRoute
                  exact
                  path="/categories/:id"
                  token={session.token}
                  component={CategoriesFormView}
                />

                <ProtectedRoute
                  exact
                  path="/productPrices/categories"
                  token={session.token}
                  component={ProductPrices}
                />

                <ProtectedRoute
                  exact
                  path="/productPrices/categories/:id"
                  token={session.token}
                  component={ProductPriceCategory}
                />
              </Switch>
            </Box>
          </BrowserRouter>
        </ThemeProvider>
      </Box>
    </>
  );
};

export default App;
