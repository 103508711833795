import React from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";
import AddEditUserForm from "../../../components/PageComponents/Users/AddEditUserForm";
import useScreenSize from "../../../hooks/useScreenSize";
import useStoreSessionSelector from "../../../hooks/useStoreSessionSelector";
import {
  ICategory,
  ICategoryFactory,
  ISubcategory,
} from "../../../interfaces/Category";
import { TFormStates } from "../../../interfaces/Forms";
import CategoryService from "../../../services/Entities/categoryService";
import SubCategoryService from "../../../services/Entities/subCategoryService";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../store/actions/alerts";
import { CategoryForm } from "../Components";
import "./CategoriesFormView.scss";
import { IAxiosResponse } from "../../../interfaces/Response";
import { usePermissionChecker } from "../../../hooks/useRoles";
// import showErrorAlert from '../../../components/Alerts/ErrorAlert/ErrorAlert';

export const CategoriesFormView = () => {
  const [formState, setFormState] = React.useState<TFormStates>("create");
  const [object, setObject] = React.useState<ICategory>(ICategoryFactory);
  const [userRole, setUserRole] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const categoryService = new CategoryService();
  const subcategoryService = new SubCategoryService();

  const session = useStoreSessionSelector();
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const device = useScreenSize();
  const dispatch = useDispatch();

  const permissionChecker = usePermissionChecker();

  const deleteResourceHandler = () => {
    if (!permissionChecker("delete-category", true)) return;

    if (!id) {
      dispatch(
        showErrorAlert({
          message: "There is no entity to delete! You are in create mode.",
        })
      );
    } else {
      const payload = {
        id: id,
        token: session.token,
      };
      categoryService
        .deleteResource(payload)
        .then((res) => history.push("/categories"))
        .finally(() => setLoading(false));
    }
  };

  const fetchSpecificResource = () => {
    setLoading(true);
    const payload = {
      token: session.token,
    };
    categoryService
      .getSpecificResource(id, payload)
      .then((res) => {
        setObject(res["data"]["data"][0]);
        setUserRole(res["data"]["data"][0].role);
        setFormState("edit");
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (id) {
      fetchSpecificResource();
    }
  }, [id]);

  const submitResource = () => {
    if (formState === "create") {
      if (!permissionChecker("create-category", true)) return;
      setLoading(true);

      const payload = {
        ...object,
        role: userRole,
        token: session.token,
      };
      categoryService
        .postResource(payload)
        .then((res) => {
          dispatch(showSuccessAlert("Submitted Successfully!"));
          history.push("/categories/" + res["data"]["data"]["_id"]);
        })
        .finally(() => setLoading(false));
    } else {
      if (!permissionChecker("edit-category", true)) return;
      setLoading(true);

      const payload = {
        id: id,
        ...object,
        role: userRole,
        token: session.token,
      };
      categoryService
        .patchResource(payload)
        .then((res) => {
          dispatch(showSuccessAlert("Submitted Successfully!"));

          fetchSpecificResource();
        })
        .finally(() => setLoading(false));
    }
  };

  const createSubcategory = (subcategory: Partial<ISubcategory>) => {
    if (subcategory.name == "")
      return dispatch(
        showErrorAlert({
          message: "Please enter new subcategory before submit!!",
        })
      );

    if (!permissionChecker("create-category", true)) return;
    setLoading(true);

    subcategoryService
      .postResource({ ...subcategory, token: session.token })
      .then((res: IAxiosResponse<ISubcategory>) => {
        console.log("Response ", res);
        categoryService
          .patchResource({
            id: id,
            ...object,
            subcategories: object.subcategories
              ? [...object.subcategories, res.data.data]
              : [res.data.data],
            role: userRole,
            token: session.token,
          })
          .then((res: any) =>
            dispatch(showSuccessAlert("Created Successfully"))
          );
      })
      .finally(() => {
        setLoading(false);
        fetchSpecificResource();
      });
  };

  const deleteSubcategory = (subcategory: ISubcategory) => {
    if (!permissionChecker("delete-category", true)) return;

    const allowed: boolean = window.confirm(
      `Are you sure you want to delete the subcategory ${subcategory.name}?`
    );
    if (!allowed) return;

    setLoading(true);

    subcategoryService
      .deleteResource({ id: subcategory._id, token: session.token })
      .then((res: any) => {
        dispatch(showSuccessAlert("Deleted Successfully"));
        fetchSpecificResource();
      })
      .finally(() => setLoading(false));
  };

  const editSubcategory = (subcategory: ISubcategory) => {
    if (!permissionChecker("edit-category", true)) return;

    const allowed: boolean = window.confirm(
      `Are you sure you want to edit the subcategory ${subcategory.name}?`
    );
    if (!allowed) return;

    setLoading(true);

    subcategoryService
      .patchResource({
        id: subcategory._id,
        ...subcategory,
        token: session.token,
      })
      .then((res: any) => {
        dispatch(showSuccessAlert("Deleted Successfully"));
        fetchSpecificResource();
      })
      .finally(() => setLoading(false));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "scroll",
      }}
    >
      <GenericHeader
        deleteResourceHandler={permissionChecker("delete-category") ? deleteResourceHandler : undefined}
        selectedRows={[{id}]}
        model="category"
      />

      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            padding: 5,
            margin: 5,
            width: device === "mobile" ? "100%" : "75%",
          }}
          className="card shadow-lg"
        >
          <CategoryForm
            object={object}
            setObject={setObject}
            loading={loading}
            submit={submitResource}
            formState={formState}
            deleteSubcategory={deleteSubcategory}
            editSubcategory={editSubcategory}
            createSubcategory={createSubcategory}
          />
        </Box>
      </Box>
    </Box>
  );
};
