import { useSelector } from "react-redux";

interface sessionToken {
  session: {
    token: string
  }
}

const useStoreSessionSelector = () => {
  const selectStoreAlerts = (state: sessionToken) => state.session
  return useSelector(selectStoreAlerts);
};

export default useStoreSessionSelector