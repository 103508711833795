import React, { useEffect, useState } from "react";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faAngleDoubleLeft,
    faAngleDoubleRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Box,
    Card, Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import placeholderPic from "../../assets/no_data_x.gif";
import CustomButton from "../../components/CustomButton/CustomButton";
import GenericHeader from "../../components/GenericHeader/GenericHeader";
import GenericTable from "../../components/GenericTable/GenericTable";
import "../../components/GenericTable/GenericTable.scss";
import Modal from "../../components/Modal/Modal";
import productsTableConfig from "../../configs/productsTable";
import { usePermissionChecker } from "../../hooks/useRoles";
import useStoreSessionSelector from "../../hooks/useStoreSessionSelector";
import { ICategory } from "../../interfaces/Category";
import { IProduct } from "../../interfaces/Products";
import { TableConfig } from "../../interfaces/tableConfig";
import {
    CategoriesApi,
    ProductsApi
} from "../../services/Entities/ProductPricingService";
import AddEditViewProduct from "../AddEditViewProduct/AddEditViewProduct";
import { ProductPrintModal } from "../ProductPrintModal/ProductPrintModal";
import { UpdatedProductPricesSection } from "./components/UpdatedProductPricesSection/UpdatedProductPricesSection";
import "./ProductPriceCategory.scss";

const ProductPriceCategory = () => {
    const { id }: { id: string } = useParams();

    const categoriesApi = new CategoriesApi();
    const productsApi = new ProductsApi();

    const [subcategoryList, setSubcategoryList] = useState<ICategory[]>([]);
    const [productList, setProductList] = useState<IProduct[]>([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [productSelectedRows, setProductSelectedRows] = useState([]);
    const [page, setPage] = useState(0);
    const [productPage, setProductPage] = useState(0);
    const [selectedSubcategory, setSelectedSubcategory] = useState<string | undefined>(undefined);
    const [selectedProduct, setSelectedProduct] = useState<string | undefined>(undefined);
    const [collapsed, setCollapsed] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [productsRowsPerPage, setProductsRowsPerPage] = useState(5);
    const [modal, setModal] = useState(false);
    const session = useStoreSessionSelector();
    const [subcategoryLoading, setSubcategoryLoading] = useState(false);
    const [productLoading, setProductLoading] = useState(false);
    const [active, setActive] = useState<ICategory>({});
    const [title, setTitle] = useState("");

    const [productsToPrint, setProductsToPrint] = useState<IProduct[]>([]);
    const [loadingPrintModal, setLoadingPrintModal] = useState(false);
    const [printModalOpen, setPrintModalOpen] = useState(false);
    const [filteredProductColumns, setFilteredProductColumns] = useState<TableConfig[]>([]);

    const permissionChecker = usePermissionChecker();

    let [numberOfData, setNdata] = useState<number>(0);
    const [lastUpdatedProductDate, setLastUpdatedProductDate] = useState<string>()
    const [priceUpdatedProducts, setPriceUpdatedProducts] = useState<IProduct[]>([]);

    React.useEffect(() => {
        let filteredTableColumns = [...productsTableConfig];

        if (permissionChecker("see-retail-price"))
            filteredTableColumns.push({
                heading: "Retail",
                attribute: "retail_price",
                type: "currency",
            });
        if (permissionChecker("see-cash-price"))
            filteredTableColumns.push({
                heading: "Cash",
                attribute: "cash_price",
                type: "currency",
            });
        if (permissionChecker("see-best-cash-price"))
            filteredTableColumns.push({
                heading: "Best Cash",
                attribute: "best_cash_price",
                type: "currency",
            });
        if (permissionChecker("see-wholesale-price"))
            filteredTableColumns.push({
                heading: "Special Wholesale",
                attribute: "special_wholesale_price",
                type: "currency",
            });
        if (permissionChecker("see-your-price"))
            filteredTableColumns.push({
                heading: "Your Price",
                attribute: "your_price",
                type: "currency",
            });

        setFilteredProductColumns(filteredTableColumns);
    }, []);

    const exportDataAsPDF = () => {
        console.log("Exporting data as pdf...");
        setPrintModalOpen(true);
        fetchAllProductsToPrint();
    };

    const fetchAllProductsToPrint = () => {
        setLoadingPrintModal(true);

        productsApi
            .getAllResources({ token: session.token, query: { category: id } })
            .then((response) => setProductsToPrint(response.data.data))
            .finally(() => setLoadingPrintModal(false));
    };


    // subcategory-related methods
    const fetchResource = () => {
        setSubcategoryLoading(true);
        const payload = {
            token: session.token,
        };
        categoriesApi
            .getSpecificResource(id, payload)
            .then((res) => {
                setNdata(res.data.total);
                setSubcategoryList(res["data"]["data"][0]["subcategories"]);
                setTitle(res["data"]["data"][0]["name"]);
            })
            .finally(() => setSubcategoryLoading(false));
    };

    const subcategoryRowTriggerAction = (row: ICategory) => {
        setSelectedSubcategory(row._id);
    };

    const subcategoryHandlePageChange = (e: any, value: any) => {
        setPage(value);
    };

    const subcategoryHandleRowsChange = (e: { target: { value: string } }) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    };

    const subcategoryHandleRowSelectionChange = (data: any) => {
        setSelectedRows(data);
    };

    // products-related methods
    const fetchProducts = () => {
        setProductLoading(true);

        if (selectedSubcategory) {
            productsApi
                .getSpecificResource(`subcategory/${selectedSubcategory}`, {})
                .then((res) => setProductList(res["data"]["data"]))
                .finally(() => setProductLoading(false));
        } else {
            productsApi
                .getAllResources({ token: session.token, query: { category: id } })
                .then((res) => setProductList(res["data"]["data"]))
                .finally(() => setProductLoading(false));
        }
    };

    const fetchUpdated = () => {
        productsApi
            .getAllResources({
                query: {
                    attribute: "price_updated",
                    from: dayjs().subtract(1, 'day').startOf("day").unix() * 1000,
                    to: dayjs().unix() * 1000,
                    category: id,
                }
            })
            .then((res) => {
                if (res.data?.data?.length) {
                    const sorted = res.data?.data?.sort((a, b) => a.price_updated - b.price_updated).reverse()
                    const d = new Date(sorted[res.data?.data.length - 1].price_updated);
                    setLastUpdatedProductDate(d?.toLocaleDateString())
                    setPriceUpdatedProducts(sorted)
                } else {
                    setLastUpdatedProductDate('')
                }
            })
    };

    const triggerModal = () => {
        setModal(true);
        setSelectedProduct(undefined);
    };

    const handleClose = () => {
        setModal(false);
    };

    const productRowTriggerAction = (row: IProduct) => {
        setSelectedProduct(row._id);
        setModal(true);
    };

    const productHandlePageChange = (e: any, value: any) => {
        setProductPage(value);
    };

    const productHandleRowsChange = (e: { target: { value: string } }) => {
        setProductsRowsPerPage(parseInt(e.target.value));
        setProductPage(0);
    };

    const productHandleRowSelectionChange = (data: any) => {
        setProductSelectedRows(data);
    };

    const refreshData = (e: boolean) => {
        if (e === true) {
            fetchProducts();
        }
    };

    const deleteProducts = () => {
        setProductLoading(true);
        const payload = {
            token: session.token,
            data: productSelectedRows,
        };
        productsApi
            .deleteManyResource(payload)
            .then(() => fetchProducts())
            .finally(() => setProductLoading(false));
    };

    React.useEffect(() => fetchResource(), []);
    React.useEffect(() => fetchProducts(), [selectedSubcategory]);
    React.useEffect(() => fetchUpdated(), [id]);

    const handleSearch = (value: string) => {
        if (value && value !== "") {
            setProductLoading(true);
            const payload = {
                query: {
                    category: id,
                    query_all: value
                }
            };
            productsApi
                .getAllResources(payload)
                .then((response) => {
                    setProductList(response.data.data);
                    let tempSub: ICategory[] = [];
                    response.data.data.map((row: IProduct) =>
                        tempSub.push(row.subcategory)
                    );
                    setSubcategoryList([...tempSub]);
                })
                .finally(() => setProductLoading(false));
        } else {
            fetchProducts();
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "100vh",
                overflow: "scroll",
            }}
        >
            <Modal onClose={handleClose}
                size="medium"
                state={modal}>
                {/* @ts-ignore */}
                <AddEditViewProduct setModal={setModal}
                    modal={modal}
                    id={selectedProduct}
                    refreshData={refreshData} />
            </Modal>

            <GenericHeader
                onSearch={handleSearch}
                addResourceHandler={permissionChecker("create-product") ? triggerModal : undefined}
                deleteResourceHandler={permissionChecker("delete-product") ? deleteProducts : undefined}
                selectedRows={productSelectedRows}
                model="product"
                customButtonsLabels={["Export Data as PDF 📃"]}
                customButtonsHandlers={[() => exportDataAsPDF()]}
            />

            <div className="m-4 h-100">
                <UpdatedProductPricesSection
                    updateDate={lastUpdatedProductDate}
                    onDismiss={() => setLastUpdatedProductDate('')}
                    priceUpdatedProducts={priceUpdatedProducts}
                />

                <Typography color="primary" variant="h1" className="mb-4">
                    {title}
                </Typography>
                <Grid container spacing={2} className="h-100">
                    <Grid
                        item
                        xl={collapsed ? 1 : 4}
                        lg={collapsed ? 1 : 4}
                        md={collapsed ? 1 : 4}
                        sm={collapsed ? 1 : 4}
                        xs={12}
                        className="h-100"
                        style={{ transition: "0.3s all" }}
                    >
                        <Card className="h-100 overflow-auto">
                            {collapsed ? (
                                <div className="d-flex justify-content-center p-4">
                                    <CustomButton
                                        className="iconButton"
                                        onClick={() => setCollapsed(!collapsed)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faAngleDoubleRight as IconProp}
                                            className="icon"
                                        />
                                    </CustomButton>
                                </div>
                            ) : (
                                <>
                                    <div className="d-flex justify-content-between p-4">
                                        <Typography
                                            variant="h4"
                                            color="textSecondary"
                                            style={{ fontSize: "20px" }}
                                        >
                                            Subcategories
                                        </Typography>
                                        <CustomButton
                                            className="iconButton"
                                            onClick={() => setCollapsed(!collapsed)}
                                            style={{ padding: "0px 5px" }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faAngleDoubleLeft as IconProp}
                                                className="icon"
                                            />
                                        </CustomButton>
                                    </div>

                                    <Box className="usersList">
                                        <TableContainer className="tableContainer">
                                            <Table className="usersListTable">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>Creation Date</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {subcategoryList
                                                        .slice(
                                                            page * rowsPerPage,
                                                            page * rowsPerPage + rowsPerPage
                                                        )
                                                        .map((row: ICategory, index: number) => (
                                                            <TableRow
                                                                key={index}
                                                                className={
                                                                    active === row
                                                                        ? " activeRow "
                                                                        : "" + " customRow"
                                                                }
                                                                onClick={() => {
                                                                    subcategoryRowTriggerAction(row);
                                                                    setActive(row);
                                                                }}
                                                            >
                                                                <TableCell>
                                                                    {row.name ? row.name : "none"}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {row.created_at
                                                                        ? dayjs(row.created_at).format("MM/DD/YYYY")
                                                                        : "none"}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            className="pagination"
                                            component="div"
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                            count={subcategoryList.length}
                                            onPageChange={subcategoryHandlePageChange}
                                            onRowsPerPageChange={subcategoryHandleRowsChange}
                                        />
                                    </Box>
                                </>
                            )}
                        </Card>
                    </Grid>
                    <Grid
                        item
                        xl={collapsed ? 11 : 8}
                        lg={collapsed ? 11 : 8}
                        md={collapsed ? 11 : 8}
                        sm={collapsed ? 11 : 8}
                        xs={12}
                        className="h-100"
                        style={{ transition: "0.3s all" }}
                    >
                        <Card className="h-100">
                            <div className="p-4">
                                <Typography
                                    variant="h4"
                                    color="textSecondary"
                                    style={{ fontSize: "20px" }}
                                >
                                    Products
                                </Typography>
                            </div>
                            <GenericTable
                                list={productList}
                                totalCount={productList?.length}
                                onSelectedChange={productHandleRowSelectionChange}
                                selectedRows={productSelectedRows}
                                page={productPage}
                                numberOfData={numberOfData}
                                // @ts-ignore
                                loading={productLoading}
                                rowsPerPage={productsRowsPerPage}
                                onRowsChange={productHandleRowsChange}
                                onPageChange={productHandlePageChange}
                                image={placeholderPic}
                                tableConfig={filteredProductColumns}
                                model="product"
                                passRow={productRowTriggerAction}
                                frontendPagination={true}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </div>

            <ProductPrintModal
                state={printModalOpen}
                onClose={setPrintModalOpen}
                loading={loadingPrintModal}
                products={productsToPrint}
            />
        </Box>
    );
};

export default ProductPriceCategory;
