import { CircularProgress } from "@mui/material";
import React from "react";
import { LoginMobileInterface } from "./LoginMobile";

const LoginForm: React.FC<LoginMobileInterface> = ({
  setEmail,
  setPassword,
  handleRememberMe,
  login,
  loading,
}: LoginMobileInterface) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        login();
      }}
    >
      <>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            aria-describedby="emailHelp"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="d-flex justify-content-between">
          <div className="mb-3 form-check d-flex justify-content-start">
            <input
              type="checkbox"
              className="form-check-input"
              id="checkbox"
              onChange={(e) => handleRememberMe(e)}
            />
            <label className="form-check-label" htmlFor="checkbox">
              Remember Me
            </label>
          </div>
          <a href="/forgot" className="d-flex justify-content-end">
            Forgot password?
          </a>
        </div>
        <button
          type="submit"
          className="btn btn-primary w-100 mt-2"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress
              size={"2em"}
              style={{ color: "inherit", margin: "-0.5em auto" }}
            />
          ) : (
            "Login"
          )}
        </button>
      </>
    </form>
  );
};

export default LoginForm;
