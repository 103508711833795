import React, { useState } from "react";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import placeholderPic from "../../../assets/no_data_x.gif";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";
import GenericTable from "../../../components/GenericTable/GenericTable";
import categories from "../../../configs/categories.tableconfig";
import useStoreSessionSelector from "../../../hooks/useStoreSessionSelector";
import { ICategory } from "../../../interfaces/Category";
import CategoryService from "../../../services/Entities/categoryService";
import { usePermissionChecker } from "../../../hooks/useRoles";

export const CategoriesTableView = () => {
  const [list, setList] = useState<ICategory[]>([]);
  const [selectedRows, setSelectedRows] = useState<ICategory[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const session = useStoreSessionSelector();
  const history = useHistory();
  const entityService = new CategoryService();

  const permissionChecker = usePermissionChecker();

  let [numberOfData, setNdata] = useState<number>(0);

  const handleSearch = (value: string) => {
    if (value && value !== "") {
      setLoading(true);
      const payload = {
        query: {
          query_all: value,
        },
      };
      entityService
        .getAllResources(payload)
        .then((response) => setList(response.data.data))
        .finally(() => setLoading(false));
    } else {
      fetchData();
    }
  };

  const fetchData = () => {
    setLoading(true);
    const payload = {
      token: session.token,
      query: {
        page: page,
        pageSize: rowsPerPage,
      },
    };
    entityService
      .getAllResources(payload)
      .then((response) => {
        setNdata(response.data.total);
        return setList(response.data.data);
      })
      .finally(() => setLoading(false));
  };

  const deleteEntity = () => {
    if (!permissionChecker("delete-category", true)) return;

    setLoading(true);

    entityService
      .deleteManyResource({ token: session.token, data: selectedRows })
      .then(() => fetchData())
      .finally(() => setLoading(false));
  };

  React.useEffect(fetchData, [page, rowsPerPage]);

  const navigateToAddPage = () => history.push(`categories/add`);

  const rowTriggerAction = (row: any) => history.push(`categories/${row?._id}`);

  const handlePageChange = (e: any, value: any) => setPage(value);

  const handleRowsChange = (e: { target: { value: string } }) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
  };

  const handleRowSelectionChange = (data: any) => {
    setSelectedRows(data);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "scroll",
      }}
    >
      <GenericHeader
        onSearch={handleSearch}
        addResourceHandler={permissionChecker("create-category") ? navigateToAddPage : undefined}
        deleteResourceHandler={permissionChecker("delete-category") ? deleteEntity : undefined}
        selectedRows={selectedRows}
        model="category"
      />
      <div className="p-4">
        <h2 className="mb-4">Categories</h2>
        <GenericTable
          list={list}
          totalCount={list.length}
          onSelectedChange={handleRowSelectionChange}
          selectedRows={selectedRows}
          page={page}
          numberOfData={numberOfData}
          // @ts-ignore
          loading={loading}
          rowsPerPage={rowsPerPage}
          onRowsChange={handleRowsChange}
          onPageChange={handlePageChange}
          image={placeholderPic}
          tableConfig={categories}
          model="category"
          passRow={rowTriggerAction}
        />
      </div>
    </Box>
  );
};
