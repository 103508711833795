import { faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, InputAdornment, Menu, TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import useScreenSize from "../../hooks/useScreenSize";
import CustomButton from "../CustomButton/CustomButton";
import { ConfirmDialog } from "../Dialogs";
import GoBackButton from "../GoBackButton/GoBackButton";
import PageHeader from "../PageHeader";
import "./GenericHeader.scss";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";

interface GenericHeaderType {
  selectedRows?: any[];
  onSearch?: any;
  model?: string;
  addResourceHandler?: () => void;
  deleteResourceHandler?: () => void;
  // For any additional buttons
  customButtonsLabels?: React.ReactChild[];
  customButtonsHandlers?: (() => void)[];
}

const GenericHeader = (params: GenericHeaderType) => {
  const [query, setQuery] = useState("");
  const [value] = useDebounce(query, 1000);
  const device = useScreenSize();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchor = useRef<HTMLAnchorElement>(null);

  const initConfirmConfig = {
    title: "",
    body: "",
    visible: false,
  };

  React.useEffect(() => {
    if (params.onSearch) {
      params.onSearch(value);
    }
  }, [value]);

  const [confirmConfig, setConfirmConfig] = useState(initConfirmConfig);

  const confirmDelete = () => {
    setConfirmConfig({
      title: "Please Confirm" || "",
      body: `Are you sure you want to delete this ${params.model}?` || "",
      visible: true,
    });
  };

  return (
    <PageHeader>
      <>
        <Box className="searchBox">
          <GoBackButton />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="searchButton"
                      color="primary"
                    />
                  </InputAdornment>
                ),
              }}
              className="outlinedInput"
              fullWidth
              placeholder="Search..."
              variant="outlined"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Box>
        </Box>
        <Box
          className="actionsBox"
          style={device === "mobile" ? { display: "flex" } : {}}
        >
          <ConfirmDialog
            setConfirmConfig={setConfirmConfig}
            confirmConfig={confirmConfig}
            executeActionCallback={params.deleteResourceHandler}
          />
          <Button
            sx={{ display: { md: "none" } }}
            ref={menuAnchor as any}
            onClick={() => setMenuOpen(true)}
          >
            <FontAwesomeIcon size={"lg"} icon={faEllipsisV} />
          </Button>

          {/* This is a loop to generate custom buttons */}
          <Box sx={{ display: { md: "inherit", xs: "none" }, gap: "inherit" }}>
            {params.customButtonsHandlers?.map((customButtonHandler, index) => (
              <CustomButton
                variant="contained"
                onClick={customButtonHandler}
                sx={{ padding: "5px 15px", margin: "0px 10px", color: "white" }}
              >
                {params?.customButtonsLabels &&
                params?.customButtonsLabels[index]
                  ? params?.customButtonsLabels[index]
                  : " - "}
              </CustomButton>
            ))}
            {/* Add resource button for desktop */}
            {params.addResourceHandler && (
              <CustomButton
                className="iconButton"
                onClick={params.addResourceHandler}
                sx={{ padding: "0px 5px" }}
              >
                <FontAwesomeIcon icon={faPlus} className="icon" />
              </CustomButton>
            )}
            {/* Delete resource button for desktop */}
            {params.deleteResourceHandler && (
              <CustomButton
                className="iconButton"
                onClick={confirmDelete}
                disabled={!(params.selectedRows && params.selectedRows?.length)}
                sx={{ padding: "0px 5px" }}
              >
                <FontAwesomeIcon icon={faTrash} className="icon" />
              </CustomButton>
            )}
          </Box>

          {/* This is a menu for mobile view, inside it we loop over the custom buttons */}
          <Menu
            anchorEl={menuAnchor.current}
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
          >
            <Box display={"grid"} gap={"5px"}>
              {params.customButtonsHandlers?.map(
                (customButtonHandler, index) => (
                  <CustomButton
                    variant="contained"
                    onClick={(e: any) => {
                      setMenuOpen(false);
                      // @ts-ignore
                      customButtonHandler(e);
                    }}
                    sx={{ padding: "5px 15px", margin: "3px", color: "white" }}
                  >
                    {params?.customButtonsLabels &&
                    params?.customButtonsLabels[index]
                      ? params?.customButtonsLabels[index]
                      : " - "}
                  </CustomButton>
                )
              )}
              {/* Add resource button for mobile */}
              {params.addResourceHandler && (
                <CustomButton
                  variant="contained"
                  onClick={params.addResourceHandler}
                  sx={{ padding: "5px 15px", margin: "3px", color: "white" }}
                >
                  <FontAwesomeIcon icon={faPlus} className="icon" />
                </CustomButton>
              )}
              {/* Delete resource button for mobile */}
              {params.deleteResourceHandler && (
                <CustomButton
                  variant="contained"
                  onClick={confirmDelete}
                  disabled={params.selectedRows && !params.selectedRows?.length}
                  sx={{ padding: "5px 15px", margin: "3px", color: "white" }}
                >
                  <FontAwesomeIcon icon={faTrash} className="icon" />
                </CustomButton>
              )}
            </Box>
          </Menu>
        </Box>
      </>
    </PageHeader>
  );
};

export default GenericHeader;
