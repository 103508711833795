import React, { useMemo, useState } from "react"
import { IAxiosResult } from "../../interfaces/Response"
import { User } from "../../interfaces/Users"
import useStoreSessionSelector from "../useStoreSessionSelector"
import { useAuthService } from "./useService"

export const useFreshUserData = () => {
    const service = useAuthService();
    const session = useStoreSessionSelector();
    
    const [loading, setLoading] = useState<boolean>(true);
    const [result, setResult] = useState<User>();
    const [error, setError] = useState<any>();

    useMemo(() => {
        service
            .getSpecificResource('me', {token: session.token})
            .then((response: IAxiosResult<User>) => setResult(response.data.result))
            .catch((e) => setError(e))
            .finally(() =>  setLoading(false));
    }, [session, service]);
    
    return {
        loading,
        result,
        error
    };
}


export interface IAPIRequestHookResponse {
    request: Function,
    error: boolean,
    loading: boolean,
    result: any
}

export const usePostUserData = (): IAPIRequestHookResponse => {
    const service = useAuthService();
    const session = useStoreSessionSelector();
    
    const [loading, setLoading] = useState<boolean>(true);
    const [result, setResult] = useState<any>();
    const [error, setError] = useState<boolean>(false);

    const apiCallMethod = React.useCallback((userData: User) => {
        service
            .postResource({...userData, token: session.token})
            .then((response: IAxiosResult<User>) => setResult(response.data.result))
            .catch((e) => setError(!!e))
            .finally(() =>  setLoading(false));
    }, [service, session])

    return {
        request: apiCallMethod,
        loading,
        result,
        error
    }
}

export const useGetSpecificUser = (): IAPIRequestHookResponse => {
    const service = useAuthService();
    const session = useStoreSessionSelector();
    
    const [loading, setLoading] = useState<boolean>(true);
    const [result, setResult] = useState<User>();
    const [error, setError] = useState<boolean>(false);

    const apiCallMethod = React.useCallback((id: string, payload: any) => {
        service
            .getSpecificResource(id, {...payload, token: session.token})
            .then((response: IAxiosResult<User>) => setResult(response.data.result))
            .catch((e) => setError(!!e))
            .finally(() =>  setLoading(false));
    }, [service, session])

    return {
        request: apiCallMethod,
        loading,
        result,
        error
    }
}