import { CircularProgress, Dialog } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import useStoreSessionSelector from '../../hooks/useStoreSessionSelector';
import axiosFactory from "../../services/AxiosInstance";
import AuthenticationService from '../../services/Entities/AuthenticationService';
import { showSuccessAlert } from '../../store/actions/alerts';
import { startSession } from '../../store/actions/session';
import "./QRModal.scss";


const QRModal: React.FC<any> = ({ 
    open = false, 
    setOpen, 
    imgUrl = "", 
    history, 
    validationMode,
    email = "",
    password = "",
    validated = false
    }: any) => {
        const [otpTokenArr, setOtpTokenArr] = React.useState<any[]>([undefined, undefined, undefined, undefined, undefined, undefined]);
        const [loading, setLoading] = React.useState<boolean>(false);
    const dispatch = useDispatch();
    const authApi = new AuthenticationService();
    const axiosInst = axiosFactory();
    const session = useStoreSessionSelector();


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        // set otpToken
        let otpTokenTemp: any[] = [...otpTokenArr];
        otpTokenTemp.splice(index, 1, e.target.value);
        setOtpTokenArr([...otpTokenTemp]);
        // autofocus on next text box
        let inputBox = document.getElementById((index + 1).toString());
        inputBox?.focus();
        // submit automatically after user inputs last number
        if(index === 5) {
            if (e.target.value.length){
                setTimeout(() => {
                    handleSubmit(otpTokenTemp.join(""));
                }, 300);
            }
        }
    }

    const handleSubmit = (otpToken: string) => {
        if (validationMode) {
            const payload = {
                otpToken: otpToken,
                token: session.token
            }
            authApi
                .postSpecificResource("2fa/confirm", payload)
                .then(async (response) => {
                    dispatch(showSuccessAlert("2FA enabled!"));
                    const userData = await axiosInst.get("auth/me", {headers: {Authorization: `Bearer ${session.token}`}})
                    sessionStorage.setItem("USER_INFO", JSON.stringify(userData.data.result));
                    setOpen(false);
                })
                .finally(() => setLoading(false));
        }
        else {
            const payload = {
                otpToken: otpToken,
                email: email,
                password: password
            }
            authApi
                .postSpecificResource("login/validate", payload)
                .then((response) => {
                    dispatch(startSession(response.data.token, history));
                })
                .finally(() => setLoading(false));
        }
    }

    const handleClose = () => setOpen(false)

    return (
        <Dialog
            open={open}
            onClose={handleClose}>
            <div className="p-3">
                {validationMode ? (
                    <>
                <div className="d-flex justify-content-center">
                    <img src={imgUrl} />
                </div>
                <h5 className="text-center">Scan QR Code</h5>
                </>
                ) : (
                    <h5 className="text-center">Please enter your OTP</h5>
                )}
                <div className="d-flex">
                    {[0, 1, 2, 3, 4, 5].map((input) => (
                        <input
                            key={input}
                            className="numberInput"
                            id={input.toString()}
                            type="text"
                            maxLength={1}
                            onChange={(e) => handleChange(e, input)} />
                    ))}
                </div>
                <div className="p-2">
                    <button className="btn btn-secondary w-100"
                        onClick={() => handleSubmit(otpTokenArr.join(""))}>
                        {loading ? <CircularProgress style={{ fontSize: "16px", color: "white" }} /> : "Send Code"}
                    </button>
                </div>
            </div>

        </Dialog>
    )
}

export default QRModal
