import React from "react";

export interface ForgotInterface {
  setEmail: any;
  forgot: any;
  email: string;
}
const ForgotForm: React.FC<ForgotInterface> = ({
  email,
  setEmail,
  forgot,
}: ForgotInterface) => {
  return (
    <form>
      <>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            aria-describedby="emailHelp"
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            type="button"
            onClick={forgot}
            className="btn btn-primary w-100 mt-2"
          >
            Send to Email
          </button>
        </div>
      </>
    </form>
  );
};

export default ForgotForm;
