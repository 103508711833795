export interface ICategory {
    subcategories?: ISubcategory[],
    created_at?: Date,
    updated_at?: Date,
    deleted_at?: Date,
    _id?: string,
    name?: string,
}

export interface ISubcategory {
    _id?: string
    name: string
    updated_at?: number
    created_at?: number
    deleted_at?: number
}

export const ICategoryFactory = (attributes: Partial<ICategory> = {}): ICategory => {
    return {
        name: '',
        subcategories: [],
        ...attributes
    }
}

export const ISubcategoryFactory = (attributes: Partial<ISubcategory> = {}): ISubcategory => {
    return {
        name: '',
        ...attributes
    }
}