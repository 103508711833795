import {useMediaQuery} from "@mui/material";

const useScreenSize = (): string => {
    const mobile = useMediaQuery("@media (max-width: 425px)")
    const tablet = useMediaQuery("@media (max-width: 768px)")
    // let screenWidth = window.screen.width;
    
    if (tablet && !mobile)
        return "tablet";
    else if (mobile)
        return "mobile";
    else // if (screenWidth > 768)
        return "laptop";
}

export default useScreenSize;
