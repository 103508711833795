import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import "whatwg-fetch";
import { store } from "../store";
import { showErrorAlert } from "../store/actions/alerts";
import { logout } from "../store/actions/session";
import axiosFactory from "./AxiosInstance";
export default class Api {
  baseUrl: string = "";

  constructor(protected resource?: string) {
    this.baseUrl = process.env.REACT_APP_API_URL ?? window["REACT_APP_API_URL"] ?? "https://api-prod.venturaoperations.com/api/v1";
  }

  async doRequest(
    endpointPath: string,
    payload: any,
    method: string = ""
  ): Promise<AxiosResponse<any>> {
    const axiosInstance = axiosFactory();
    axiosInstance.interceptors.request.use(
      (request): AxiosRequestConfig => {
        return request;
      },
      (error) => {
        store.dispatch(showErrorAlert(error));
        return error;
      }
    );

    axiosInstance.interceptors.response.use(
      (response): AxiosResponse => {
        return response;
      },
      (error: AxiosError) => {
        console.log("error:", error.response);
        switch (error.response?.status) {
          case 404:
            store.dispatch(
              showErrorAlert({
                message: "🕵️‍♂️ Entity not found! Make sure what you requested exists.",
              })
            );
            break;
          case 422:
            store.dispatch(showErrorAlert(error.response?.data));
            break;
          case 500:
            store.dispatch(showErrorAlert(error.response?.data));
            break;
          case 401:
            store.dispatch(showErrorAlert(error.response?.data));
            // @ts-ignore
            store.dispatch(logout());
            break;
          case 403:
            store.dispatch(showErrorAlert(error.response?.data));
            // @ts-ignore
            // store.dispatch(logout());
            break;
          default:
            store.dispatch(
              showErrorAlert({
                message:
                  "Something is wrong and we're not sure what it is. \nMake sure the backend is up and running!",
              })
            );
            break;
        }
        return Promise.reject(error);
      }
    );

    const formDataHeaders = {
      "Content-Type": "multipart/form-data",
    };

    switch (method) {
      default: // GET Method
        return await axiosInstance.get(`${endpointPath}`, {
          params: payload.query,
        });
      case "POST":
        return await axiosInstance.post(`${endpointPath}`, payload);
      case "PUT":
        return await axiosInstance.put(
          `${endpointPath}/${payload.id}`,
          payload
        );
      case "PATCH":
        if (payload instanceof FormData) {
          return await axiosInstance.patch(
            `${endpointPath}/${payload.get("id")}`,
            payload,
            { headers: formDataHeaders }
          );
        } else {
          return await axiosInstance.patch(
            `${endpointPath}/${payload.id}`,
            payload
          );
        }
      case "DELETE":
        if (payload.data) {
          return await axiosInstance.delete(`${endpointPath}`, {
            data: payload,
          });
        } else {
          return await axiosInstance.delete(`${endpointPath}/${payload.id}`);
        }
    }
  }
}
