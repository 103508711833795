import Api from "./apiClient";

export default class ApiRequests extends Api {
  constructor(protected resource?: string) {
    super();
  }

  async postResource(payload: any): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/${this.resource}`,
      payload,
      "POST"
    );
  }

  async postSpecificResource(suffix: string, payload: any): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/${this.resource}/${suffix}`,
      payload,
      "POST"
    );
  }

  async getAllResources(payload: any): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/${this.resource}`,
      payload,
      "GET"
    );
  }

  async getSpecificResource(id: string, payload: any): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/${this.resource}/${id}`,
      payload,
      "GET"
    );
  }

  async deleteResource(payload: any): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/${this.resource}`,
      payload,
      "DELETE"
    );
  }

  async deleteManyResource(payload: any): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/${this.resource}`,
      payload,
      "DELETE"
    );
  }

  async patchResource(payload: any): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/${this.resource}`,
      payload,
      "PATCH"
    );
  }
}
